/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "7b6473ec11ec4288b8e5a1a93d5dce18",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "7b6473ec11ec4288b8e5a1a93d5dce18",
            "region": "eu-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://qz7lfzl5xnes7m5zwhetfqcef4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "checkout",
            "endpoint": "https://5rbbxotsjj.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "enrolmentmanager",
            "endpoint": "https://yx1pi1vi26.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "incomingwebhooks",
            "endpoint": "https://y4r3wyyl62.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:0f8a4463-5723-4cfe-984b-c460d4f8c894",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_b8zzmKJbI",
    "aws_user_pools_web_client_id": "sm1ulab3nebrt3a8ab4grt016",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyapp15babf61e9a3434db5e4ba3de07ba21d230900-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
